import React from 'react'
import styled, { injectGlobal } from 'styled-components'
import normalize from 'styled-normalize'
import get from 'lodash/get'

import Footer from './Footer'
import SEO from './SEO'

injectGlobal`
  @import url("https://fonts.googleapis.com/css?family=Open+Sans"); // keep here to styled footer and non-template pages
  
  ${normalize}
  
  html {
    font-family: 'Open Sans', sans-serif;
    height: 100%;
    box-sizing: border-box;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }
`

const Wrapper = styled.div`
  background-color: ${props => props.backgroundColor};
  padding-bottom: 50px; // for footer
  min-height: 100vh;
	white-space: pre-line; // allows line-breaks in frontmatter
  max-width: 1000px;
  margin: 0 auto;
`

const Layout = ({children, frontmatter, ...others}) =>
  <Wrapper backgroundColor={get('frontmatter.backgroundColor')} {...others}>
    {frontmatter && <SEO frontmatter={frontmatter} />}
    {children}
    {frontmatter && <Footer {...frontmatter} />}
  </Wrapper>

export default Layout