import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import {FaFacebook, FaInstagram} from 'react-icons/fa'

import Layout from '../components/Layout'

const Wrapper = styled.div`
  background-color: #f2f2f2;
  font-family: 'PT Sans Caption', sans-serif;
	display: flex;
	align-items: center;
	flex-direction: column;
  text-align: center;
  animation: hide;
  width: 100%;
`

const Header = styled.h2`
  font-size: 2rem;
  margin: 1rem;
`

const Image = styled.div`
  margin: 0;
`

const Logo = styled.img.attrs({
  alt: 'logo',
})`
  width: 100%;
  max-height: 50%;
`

const Text = styled.p`
  color: #122A59;
  padding: 0 1rem;
`

const Social = styled.section`
  padding: 1rem;
  width: 150px;
  display: flex;
  margin: 0 auto;
  justify-content: space-evenly;
  font-size: 1.5rem;
  
  a {
    color: #122A59;
    text-decoration: none;
  }
`

const SingleImage = ({ data: { markdownRemark: { frontmatter } } }) =>
  <Layout frontmatter={{ ...frontmatter, backgroundColor: '#f2f2f2' }}>
    <Wrapper>
      <Fade bottom ssrReveal>
        <div>
          <Image>
            <a href={frontmatter.link}><Logo src={frontmatter.image.publicURL} /></a>
          </Image>
          <Header>Our Story</Header>
          <Text>Our name comes as a tribute to <strong>María de los Ángeles Félix Güereña</strong> (8 April 1914 – 8 April 2002), she was a Mexican
            film actress and singer. She was considered one of the most important female figures of the <a href='https://en.wikipedia.org/wiki/Golden_Age_of_Mexican_cinema'>Golden Age of Mexican cinema</a>.
            She was also considered one of the most beautiful film actresses of her time, and one of the greatest erotic myths of the Spanish-language cinema.</Text>
          <Text>Known as La Doña and Maria Bonita, she was one of the most successful figures of the Latin American cinema in the 1940s and 1950s.</Text>
          <Social>
            <a href='https://www.facebook.com/lafelixbar/'><FaFacebook /></a>
            <a href='https://www.instagram.com/lafelixmx/'><FaInstagram /></a>
          </Social>
        </div>
      </Fade>
    </Wrapper>
  </Layout>

export default SingleImage

export const pageQuery = graphql`
  query SingleImageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        templateKey
        title
        description
        link
        image {
          publicURL
        }
        favicon {
          publicURL
        }
      }
    }
  }
`